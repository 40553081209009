import React from 'react';
import { Bell, X } from 'lucide-react';

// Toast Notification Component
export const ToastNotification = ({ message, onClose, darkMode }) => (
  <div className={`fixed top-4 right-4 p-4 rounded-md shadow-md z-50 ${
    darkMode ? 'bg-blue-900 text-white' : 'bg-blue-100 text-blue-900'
  }`}>
    <p>{message}</p>
    <button onClick={onClose} className="absolute top-1 right-2 text-sm">x</button>
  </div>
);

// Badge Component
export const NotificationBadge = ({ count }) => {
  if (count === 0) return null;
  
  return (
    <span className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
      {count}
    </span>
  );
};

// Notification Item Component
export const NotificationItem = ({ notification, darkMode, isLast }) => (
    <div className={`px-4 py-3 ${!isLast ? 'border-b border-gray-200' : ''} ${
      darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
    } ${!notification.read ? 'bg-opacity-50' : ''}`}>
      <div className={`text-sm font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
        {notification.message}
        {!notification.read && (
          <span className="ml-2 inline-block w-2 h-2 bg-blue-500 rounded-full"></span>
        )}
      </div>
      <div className={`text-xs ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        {notification.timestamp}
      </div>
    </div>
  );

// Notification Panel Header Component
export const NotificationPanelHeader = ({ onClear, onClose, darkMode }) => (
  <div className="flex items-center justify-between px-4 py-2 border-b border-gray-200">
    <h3 className={`text-lg font-medium ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
      Notifications
    </h3>
    <div className="flex items-center space-x-2">
      <button
        onClick={onClear}
        className={`text-sm ${darkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-600 hover:text-gray-900'}`}
      >
        Clear all
      </button>
      <button
        onClick={onClose}
        className={`p-1 rounded-full ${
          darkMode 
            ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-200' 
            : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
        }`}
      >
        <X size={16} />
      </button>
    </div>
  </div>
);

// NotificationButton Component
export const NotificationButton = ({ onClick, darkMode, count }) => (
    <button 
      className={`p-2 rounded-full relative ${
        darkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'
      }`}
      onClick={onClick}
    >
      <Bell className="h-5 w-5 text-gray-400" />
      {count > 0 && (
        <span className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
          {count}
        </span>
      )}
    </button>
  );

// Main NotificationPanel Component
export const NotificationPanel = ({ isOpen, notifications, onClose, onClear, darkMode }) => {
    if (!isOpen) return null;
  
    return (
      <div className="absolute right-0 mt-2 w-96 rounded-md shadow-lg z-50">
        <div className={`rounded-md ring-1 ring-black ring-opacity-5 ${
          darkMode ? 'bg-gray-800' : 'bg-white'
        }`}>
          <div className="py-2 z-[1000]">
            <NotificationPanelHeader onClear={onClear} onClose={onClose} darkMode={darkMode} />
            
            <div className="max-h-96 overflow-y-auto">
              {notifications.length === 0 ? (
                <div className={`px-4 py-3 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  No notifications
                </div>
              ) : (
                notifications.map((notification, index) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    darkMode={darkMode}
                    isLast={index === notifications.length - 1}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    );
};