import React from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const PowerFlowModal = ({ isOpen, onClose, darkMode }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed -inset-4 bg-black bg-opacity-50 flex items-center justify-center z-[2000]"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5, bounce: 0.3 }}
            className={`w-full max-w-lg rounded-xl shadow-2xl ${
              darkMode ? 'bg-gray-800' : 'bg-white'
            } overflow-hidden mx-4`}
            onClick={e => e.stopPropagation()}
          >
            <div className={`px-6 py-4 flex justify-between items-center border-b ${
              darkMode ? 'border-gray-700' : 'border-gray-200'
            }`}>
              <h3 className={`text-xl font-semibold ${
                darkMode ? 'text-white' : 'text-gray-900'
              }`}>
                Powerflow Analysis Results
              </h3>
              <button
                onClick={onClose}
                className={`p-2 rounded-full transition-colors ${
                  darkMode 
                    ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-200' 
                    : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                }`}
              >
                <X size={20} />
              </button>
            </div>
            
            <div className="p-6">
              <div className="space-y-6">
                {/* Status Overview Card */}
                <div className={`rounded-xl p-5 ${
                  darkMode ? 'bg-gray-700' : 'bg-blue-50'
                }`}>
                  <div className="flex justify-between items-center mb-4">
                    <h4 className={`text-lg font-semibold ${
                      darkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      Current Status
                    </h4>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                      darkMode ? 'bg-green-500/20 text-green-400' : 'bg-green-100 text-green-700'
                    }`}>
                      Active
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p className={`text-sm ${
                        darkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>
                        Accuracy Rate
                      </p>
                      <p className={`text-2xl font-bold text-green-500`}>
                        99.5%
                      </p>
                    </div>
                    <div>
                      <p className={`text-sm ${
                        darkMode ? 'text-gray-400' : 'text-gray-600'
                      }`}>
                        Processing Time
                      </p>
                      <p className={`text-2xl font-bold ${
                        darkMode ? 'text-blue-400' : 'text-blue-600'
                      }`}>
                        5 mins
                      </p>
                    </div>
                  </div>
                </div>

                {/* Performance Metrics Card */}
                <div className={`rounded-xl p-5 ${
                  darkMode ? 'bg-gray-700' : 'bg-gray-50'
                }`}>
                  <h4 className={`text-lg font-semibold mb-4 ${
                    darkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Analysis Details
                  </h4>
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p className={`text-sm ${
                          darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                          Total Hours
                        </p>
                        <p className={`text-xl font-semibold ${
                          darkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          8,760
                        </p>
                      </div>
                      <div>
                        <p className={`text-sm ${
                          darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                          Solved Cases
                        </p>
                        <p className={`text-xl font-semibold ${
                          darkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          8,716 / 8,760
                        </p>
                      </div>
                    </div>

                    {/* Progress Bar */}
                    <div className="space-y-2">
                      <div className="flex justify-between text-sm">
                        <span className={darkMode ? 'text-gray-400' : 'text-gray-600'}>
                          Solution Progress
                        </span>
                        <span className={darkMode ? 'text-gray-300' : 'text-gray-700'}>
                          99.5%
                        </span>
                      </div>
                      <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-green-500 rounded-full"
                          style={{ width: '99.5%' }}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p className={`text-sm ${
                          darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                          Average Iterations
                        </p>
                        <p className={`text-xl font-semibold ${
                          darkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          4.2
                        </p>
                      </div>
                      <div>
                        <p className={`text-sm ${
                          darkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                          Convergence Rate
                        </p>
                        <p className={`text-xl font-semibold ${
                          darkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          99.8%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PowerFlowModal;