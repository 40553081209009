import React, { useState } from 'react';
import { ChevronRight, BatteryCharging, Users, AlertTriangle, Clock, ChevronDown, Zap, GitBranch, Database } from 'lucide-react';

const CustomDropdown = ({ options, value, onChange, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = options.find(opt => opt.value === value);

  return (
    <div className="relative w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full p-3 flex items-center justify-between rounded-lg ${
          darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
        } border border-gray-200 dark:border-gray-700`}
      >
        <span>{selectedOption?.label}</span>
        <ChevronDown className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      
      {isOpen && (
        <div className={`absolute z-50 w-full mt-1 rounded-lg shadow-lg ${
          darkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
        }`}>
          {options.map((option) => (
            <button
              key={option.value}
              className={`w-full p-3 text-left hover:bg-blue-100 dark:hover:bg-blue-900 ${
                option.value === value ? 'bg-blue-50 dark:bg-blue-800' : ''
              }`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const StatCard = ({ label, value, icon: Icon }) => (
  <div className="flex items-center justify-between p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
    <div className="flex items-center space-x-3">
      {Icon && (
        <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
          <Icon className="h-5 w-5 text-blue-600 dark:text-blue-400" />
        </div>
      )}
      <div>
        <div className="text-sm text-gray-600 dark:text-gray-300">{label}</div>
        <div className="text-lg font-semibold text-gray-600 dark:text-gray-300">{value}</div>
      </div>
    </div>
  </div>
);

const RecommendationCard = ({ icon: Icon, title, subtitle, powerValue, feedersValue, darkMode }) => (
  <div className={`p-4 rounded-lg border ${
    darkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-gray-800'
  } mb-3`}>
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center space-x-3">
        <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
          <Icon className="h-5 w-5 text-blue-600 dark:text-blue-400" />
        </div>
        <div>
          <h4 className="font-medium text-gray-300">{title}</h4>
          <p className="text-sm text-gray-500 dark:text-gray-300">{subtitle}</p>
        </div>
      </div>
    </div>
    {(powerValue || feedersValue) && (
      <div className="flex space-x-4 mt-2">
        {powerValue && (
          <div>
            <div className="text-lg font-semibold text-gray-300">{powerValue}kW</div>
            <div className="text-sm text-gray-500 dark:text-gray-300">Power</div>
          </div>
        )}
        {feedersValue && (
          <div>
            <div className="text-lg font-semibold text-gray-300">{feedersValue}</div>
            <div className="text-sm text-gray-500 dark:text-gray-300">Feeders</div>
          </div>
        )}
      </div>
    )}
  </div>
);

const scenarios = [
  { value: "base", label: "Base Scenario" },
  { value: "er4109", label: "Energization (300kW): Bottom of the Feeder(p1ulv4109)" },
  { value: "er4109_1", label: "Energization (500kW): Bottom of the Feeder(p1ulv4109)" },
  { value: "er4139", label: "Energization (300kW): Center of the Feeder(p1ulv4139)" },
  { value: "er7633", label: "Energization (150kW): Top of the Feeder(p1ulv7633)" },
];

const AISummary = ({ darkMode, selectedScenario, onScenarioChange, onExpand, analysisData, isLoading }) => {
  const [activeTab, setActiveTab] = useState('insights');
  const [sortBy, setSortBy] = useState('cost');

  const getAnalysisValues = () => {
    if (!analysisData) {
      return {
        totalViolations: 560,
        underVoltageViolations: 340,
        overVoltageViolations: 120,
        averageViolationDuration: '3h 53m',
        assetConstraints: 47,
        linesCongestion: 89
      };
    }

    return {
      totalViolations: analysisData.totalViolations?.count || 560,
      underVoltageViolations: analysisData.underVoltage?.count || 340,
      overVoltageViolations: analysisData.overVoltage?.count || 120,
      averageViolationDuration: analysisData.averageViolationDuration || '3h 53m',
      assetConstraints: analysisData.assetConstraints?.count || 47,
      linesCongestion: analysisData.linesCongestion?.count || 89
    };
  };

  const analysisValues = getAnalysisValues();

  return (
    <div className={`h-full w-85 ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'} overflow-y-auto`}>
      <div className="p-4">
        <div className="flex space-x-1 p-1 bg-blue-900/20 rounded-xl mb-4">
          <button
            className={`w-full py-2.5 text-sm font-medium leading-5 rounded-lg transition-colors ${
              activeTab === 'insights'
                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-400'
                : 'text-gray-600 dark:text-gray-400 hover:bg-white/[0.12] hover:text-blue-600'
            }`}
            onClick={() => setActiveTab('insights')}
          >
            AI Insights
          </button>
          <button
            className={`w-full py-2.5 text-sm font-medium leading-5 rounded-lg transition-colors ${
              activeTab === 'assistant'
                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-400'
                : 'text-gray-600 dark:text-gray-400 hover:bg-white/[0.12] hover:text-blue-600'
            }`}
            onClick={() => setActiveTab('assistant')}
          >
            Assistant
          </button>
        </div>

        {activeTab === 'insights' ? (
          <div className="space-y-6">

            {/* Queue Overview */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">Energization queue overview</h3>
                <ChevronRight className="h-5 w-5" />
              </div>
              
              <div className="grid gap-4">
                <div className="flex items-center space-x-4 p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                  <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
                    <BatteryCharging className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-gray-600 dark:text-gray-300">4000</div>
                    <div className="text-sm text-gray-600 dark:text-gray-300">kW</div>
                    <div className="text-xs text-gray-500 dark:text-gray-400">Peak Load</div>
                  </div>
                </div>
                <div className="flex items-center space-x-4 p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                  <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
                    <Users className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div>
                    <div className="text-2xl font-bold text-gray-600 dark:text-gray-300">450</div>
                    <div className="text-sm text-gray-600 dark:text-gray-300">EV chargers</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Scenario Selection */}
            <div className="mb-6">
              <CustomDropdown
                options={scenarios}
                value={selectedScenario}
                onChange={onScenarioChange}
                darkMode={darkMode}
              />
            </div>

            {/* Analysis Results */}
            <div className="space-y-4">
              <div 
                className="flex items-center justify-between cursor-pointer"
                onClick={onExpand}
              >
                <h3 className="text-lg font-semibold">8760 analysis results</h3>
                <ChevronRight className="h-5 w-5" />
              </div>

              <div className="space-y-4">
                <div className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
                  <div className="flex justify-between items-start">
                    <div className="flex items-start space-x-3">
                      <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
                        <AlertTriangle className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                      </div>
                      <div>
                        <div className="text-sm text-gray-600 dark:text-gray-300">Total Violations</div>
                        <div className="text-2xl font-bold text-gray-600 dark:text-gray-300">{analysisValues.totalViolations}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 grid grid-cols-2 gap-4">
                    <div>
                      <div className="text-sm text-gray-600 dark:text-gray-300">Under voltage</div>
                      <div className="flex items-center">
                        <div className="text-xl font-semibold text-gray-600 dark:text-gray-300">{analysisValues.underVoltageViolations}</div>
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-600 dark:text-gray-300">Over voltage</div>
                      <div className="flex items-center">
                        <div className="text-xl font-semibold text-gray-600 dark:text-gray-300">{analysisValues.overVoltageViolations}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <StatCard
                  icon={Clock}
                  label="Average violation duration"
                  value={analysisValues.averageViolationDuration}
                />

                <div className="grid grid-cols-2 gap-4">
                  <StatCard
                    label="Constraints on assets"
                    value={analysisValues.assetConstraints}
                  />
                  <StatCard
                    label="Congestion on lines"
                    value={analysisValues.linesCongestion}
                  />
                </div>
              </div>
            </div>

            {/* Recommendations Section */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">Recommendations</h3>
              </div>

              <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500 dark:text-gray-400">Sort by</span>
                  <select 
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className={`text-sm rounded-md border ${
                      darkMode 
                        ? 'bg-gray-800 border-gray-700 text-gray-300' 
                        : 'bg-white border-gray-200 text-gray-700'
                    } p-1`}
                  >
                    <option value="cost">Cost optimized</option>
                    <option value="impact">Impact</option>
                    <option value="time">Implementation time</option>
                  </select>
                </div>

              <RecommendationCard
                icon={Zap}
                title="Demand Flexibility"
                subtitle="EV charging curtailment"
                powerValue="1900"
                feedersValue="45"
                darkMode={darkMode}
              />

              <RecommendationCard
                icon={GitBranch}
                title="Topology optimization"
                subtitle="Switch optimization"
                darkMode={darkMode}
              />

              <RecommendationCard
                icon={Database}
                title="Energy storage"
                subtitle="Battery storage systems"
                darkMode={darkMode}
              />
            </div>

          </div>
        ) : (
          <div className="flex items-center justify-center h-[calc(100%-4rem)] text-gray-500">
            Assistant functionality coming soon...
          </div>
        )}
      </div>
    </div>
  );
}

export default AISummary;