import React, { useEffect, useState } from 'react';
import { XMarkIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { parseISO, format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';

const RightPanel = ({ isOpen, onClose, panelData, darkMode, selectedScenario }) => {
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSelectedPhase(null);
    const fetchData = async () => {
      if (panelData?.data?.assetDetail?.bus_name) {
        try {
          let param = '';
          if (selectedScenario === 'base') {
            param += '?scenario=base';
          } else if (selectedScenario === 'er4109') {
            param += '?scenario=er300-4109';
          } else if (selectedScenario === 'er4109_1') {
            param += '?scenario=er500-4109';
          } else if (selectedScenario === 'er4139') {
            param += '?scenario=er300-4139';
          } else if (selectedScenario === 'er7633') {
            param += '?scenario=er150-7633';
          }
          const response = await fetch(`https://stg.ext.thinklabs.ai/backend/api/v1/network-viewer/asset-details/graph${param}&bus_name=${panelData.data.assetDetail.bus_name}`);
          const jsonData = await response.json();
          
          const processedData = jsonData.assetDetails.map(item => ({
            time: format(item.timestamp, "MMM d"),
            vmag_a: (item.vmag_a),
            vmag_b: (item.vmag_b),
            p: (item.p_a_kw + item.p_b_kw + item.p_c_kw) / 3,
            q: (item.q_a_kvar + item.q_b_kvar + item.q_c_kvar) / 3
          }));
          
          setGraphData(processedData);
        } catch (error) {
          console.error("Error fetching graph data:", error);
        }
      }
    };

    fetchData();
  }, [panelData, selectedScenario]);

  if (!panelData) return null;
  const { title, data } = panelData;

  const renderGraph = () => (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={graphData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis yAxisId="left" label={{ value: 'kW/kVAR', angle: -90, position: 'insideLeft' }} />
        <YAxis yAxisId="right" orientation="right" label={{ value: 'V (p.u.)', angle: -90, position: 'insideRight' }} />
        <Tooltip contentStyle={{ backgroundColor: darkMode ? '#1F2937' : '#FFFFFF', border: 'none', borderRadius: '8px' }} />
        <Legend />
        <Line yAxisId="left" type="monotone" dataKey="p" stroke="#8B5CF6" name="P (kW)" strokeWidth={2} />
        <Line yAxisId="left" type="monotone" dataKey="q" stroke="#10B981" name="Q (kVAR)" strokeWidth={2} />
        <Line yAxisId="right" type="monotone" dataKey="vmag_a" stroke="#F59E0B" name="Vmag A (pu)" strokeWidth={2} />
        <Line yAxisId="right" type="monotone" dataKey="vmag_b" stroke="#EF4444" name="Vmag B (pu)" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );

  const renderValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return (
          <ul className="list-disc pl-5">
            {value.map((item, index) => (
              <li key={index}>{renderValue(item)}</li>
            ))}
          </ul>
        );
      } else {
        return (
          <div className="pl-2">
            {Object.entries(value).map(([subKey, subValue]) => (
              <div key={subKey}>
                <span className="font-medium">{subKey}</span> {renderValue(subValue)}
              </div>
            ))}
          </div>
        );
      }
    }
    if (value === undefined) return;
    return ': ' + value?.toString();
  };

  const renderPhaseTable = () => {
    let phases = [];
    if (data.type === 'Line') {
      const i_mag_a = data.assetDetail?.i_mag_a || 0;
      const i_mag_b = data.assetDetail?.i_mag_b || 0;
      const i_mag_c = data.assetDetail?.i_mag_c || 0;
      const i_ang_a = data.assetDetail?.i_ang_a || 0;
      const i_ang_b = data.assetDetail?.i_ang_b || 0;
      const i_ang_c = data.assetDetail?.i_ang_c || 0;
      const violations_a = data.assetDetail?.violations?.a || false;
      const violations_b = data.assetDetail?.violations?.b || false;
      const violations_c = data.assetDetail?.violations?.c || false;
      phases = [
        { name: 'A', i_mag: i_mag_a, i_ang: i_ang_a, violated: violations_a },
        { name: 'B', i_mag: i_mag_b, i_ang: i_ang_b, violated: violations_b },
        { name: 'C', i_mag: i_mag_c, i_ang: i_ang_c, violated: violations_c },
      ].filter(phase => (phase.i_mag || phase.i_ang) !== 0);
    } else if (data.type === 'Transformer') {
      const s_mag_a = data.assetDetail?.s_mag_a || 0;
      const s_mag_b = data.assetDetail?.s_mag_b || 0;
      const s_mag_c = data.assetDetail?.s_mag_c || 0;
      const s_ang_a = data.assetDetail?.s_ang_a || 0;
      const s_ang_b = data.assetDetail?.s_ang_b || 0;
      const s_ang_c = data.assetDetail?.s_ang_c || 0;
      const violations_a = data.assetDetail?.violations?.a || false;
      const violations_b = data.assetDetail?.violations?.b || false;
      const violations_c = data.assetDetail?.violations?.c || false;
      phases = [
        { name: 'A', s_mag: s_mag_a, s_ang: s_ang_a, violated: violations_a },
        { name: 'B', s_mag: s_mag_b, s_ang: s_ang_b, violated: violations_b },
        { name: 'C', s_mag: s_mag_c, s_ang: s_ang_c, violated: violations_c },
      ].filter(phase => (phase.s_mag || phase.s_ang) !== 0);
    } else {
      const vmag_a = data.assetDetail?.vmag_a || 0;
      const vmag_b = data.assetDetail?.vmag_b || 0;
      const vmag_c = data.assetDetail?.vmag_c || 0;
      const v_ang_a = data.assetDetail?.v_ang_a || 0;
      const v_ang_b = data.assetDetail?.v_ang_b || 0;
      const v_ang_c = data.assetDetail?.v_ang_c || 0;
      const p_a = data.assetDetail?.p_a_kw || 0;
      const p_b = data.assetDetail?.p_b_kw || 0;
      const p_c = data.assetDetail?.p_c_kw || 0;
      const q_a = data.assetDetail?.q_a_kvar || 0;
      const q_b = data.assetDetail?.q_b_kvar || 0;
      const q_c = data.assetDetail?.q_c_kvar || 0;
      const violations_a = data.assetDetail?.violations?.a || false;
      const violations_b = data.assetDetail?.violations?.b || false;
      const violations_c = data.assetDetail?.violations?.c || false;

      phases = [
        { name: 'A', vmag: vmag_a, violated: violations_a, p: p_a, q: q_a, v_ang: v_ang_a },
        { name: 'B', vmag: vmag_b, violated: violations_b, p: p_b, q: q_b, v_ang: v_ang_b },
        { name: 'C', vmag: vmag_c, violated: violations_c, p: p_c, q: q_c, v_ang: v_ang_c },
      ].filter(phase => phase.vmag !== 0);
    }
    if (phases.length === 0) return null;

    const filteredPhases = selectedPhase ? phases.filter(phase => phase.name === selectedPhase) : phases;

    return (
      <div className="overflow-x-auto mt-4">
        <table className={`w-full ${darkMode ? 'text-white' : 'text-gray-900'} border-collapse`}>
          <thead>
            <tr className={`${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <th className="border border-gray-300 px-2 py-1">Phases</th>
              {filteredPhases.map(phase => (
                <th key={phase.name} className="border border-gray-300 px-2 py-1">{phase.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(data.type === 'Line') ? (
              <>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Imag</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.i_mag.toFixed(2)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Iang</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.i_ang.toFixed(2)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Violated</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">
                      <span className={`px-2 py-1 rounded-full text-xs ${phase.violated ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>
                        {phase.violated ? 'Yes' : 'No'}
                      </span>
                    </td>
                  ))}
                </tr>
              </>
            ) : (data.type === 'Transformer') ? (
              <>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Smag</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.s_mag.toFixed(2)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Sang</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.s_ang.toFixed(2)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Violated</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">
                      <span className={`px-2 py-1 rounded-full text-xs ${phase.violated ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>
                        {phase.violated ? 'Yes' : 'No'}
                      </span>
                    </td>
                  ))}
                </tr>
              </>
            ) : (
              <>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Vmag</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.vmag.toFixed(4)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Vang</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.v_ang.toFixed(2)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">P (kW)</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.p.toFixed(2)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Q (kVAR)</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">{phase.q.toFixed(2)}</td>
                  ))}
                </tr>
                <tr className={`${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                  <td className="border border-gray-300 px-2 py-1 font-medium">Violated</td>
                  {filteredPhases.map(phase => (
                    <td key={phase.name} className="border border-gray-300 px-2 py-1">
                      <span className={`px-2 py-1 rounded-full text-xs ${phase.violated ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>
                        {phase.violated ? 'Yes' : 'No'}
                      </span>
                    </td>
                  ))}
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderAssetDetails = () => {
    if (!data.assetDetail) return null;
    let presentPhases = [];
    if (data.type === 'Line') {
      const i_mag_a = data.assetDetail?.i_mag_a || 0;
      const i_mag_b = data.assetDetail?.i_mag_b || 0;
      const i_mag_c = data.assetDetail?.i_mag_c || 0;
      presentPhases = [
        { name: 'A', present: i_mag_a !== 0 },
        { name: 'B', present: i_mag_b !== 0 },
        { name: 'C', present: i_mag_c !== 0 },
      ];
    } else if (data.type === 'Transformer'){
      const s_mag_a = data.assetDetail?.s_mag_a || 0;
      const s_mag_b = data.assetDetail?.s_mag_b || 0;
      const s_mag_c = data.assetDetail?.s_mag_c || 0;
      presentPhases = [
        { name: 'A', present: s_mag_a !== 0 },
        { name: 'B', present: s_mag_b !== 0 },
        { name: 'C', present: s_mag_c !== 0 },
      ];
    } else {
      const vmag_a = data.assetDetail?.vmag_a || 0;
      const vmag_b = data.assetDetail?.vmag_b || 0;
      const vmag_c = data.assetDetail?.vmag_c || 0;
      presentPhases = [
        { name: 'A', present: vmag_a !== 0 },
        { name: 'B', present: vmag_b !== 0 },
        { name: 'C', present: vmag_c !== 0 },
      ];
    }

    const handlePhaseClick = (phaseName) => {
      setSelectedPhase(prevPhase => prevPhase === phaseName ? null : phaseName);
    };

    return (
      <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-100'} mt-4 shadow-md`}>
        <h3 className="font-semibold mb-3 text-lg">Asset Details</h3>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="font-medium">Timestamp:</span>
            <span>{parseISO(data.timestamp).toUTCString()}</span>
          </div>
          {(data.type === 'Line') ? (
            <>
              <div className="flex justify-between">
                <span className="font-medium">Asset ID:</span>
                <span>{data.assetDetail.line_name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Normal Amps:</span>
                <span>{data.assetDetail?.norm_amps.toFixed(3)}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Violated:</span>
                <span className={`px-2 py-1 rounded-full text-xs ${data.assetDetail?.violations?.a || data.assetDetail?.violations?.b || data.assetDetail?.violations?.c ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>
                  {data.assetDetail?.violations?.a || data.assetDetail?.violations?.b || data.assetDetail?.violations?.c ? 'Yes' : 'No'}
                </span>
              </div>
            </>
          ) : (data.type === 'Transformer') ? (
            <>
              <div className="flex justify-between">
                <span className="font-medium">Asset ID:</span>
                <span>{data.assetDetail.line_name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Normal HKVA:</span>
                <span>{data.assetDetail?.norm_hkva}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Violated:</span>
                <span className={`px-2 py-1 rounded-full text-xs ${data.assetDetail?.violations?.a || data.assetDetail?.violations?.b || data.assetDetail?.violations?.c ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>
                  {data.assetDetail?.violations?.a || data.assetDetail?.violations?.b || data.assetDetail?.violations?.c ? 'Yes' : 'No'}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-between">
                <span className="font-medium">Asset ID:</span>
                <span>{data.assetDetail.bus_name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Violated:</span>
                <span className={`px-2 py-1 rounded-full text-xs ${data.assetDetail?.violations?.a || data.assetDetail?.violations?.b || data.assetDetail?.violations?.c ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>
                  {data.assetDetail?.violations?.a || data.assetDetail?.violations?.b || data.assetDetail?.violations?.c ? 'Yes' : 'No'}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="mt-4">
          <span className="font-medium mb-2 block">Phases:</span>
          <div className="flex gap-2 mt-1">
            {presentPhases.map(phase => (
              <motion.button
                key={phase.name}
                onClick={() => phase.present ? handlePhaseClick(phase.name) : null}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                  phase.present
                    ? selectedPhase === phase.name
                      ? darkMode
                        ? 'bg-blue-600 text-white'
                        : 'bg-blue-500 text-white'
                      : darkMode
                      ? 'bg-red-600 text-white hover:bg-blue-600'
                      : 'bg-red-300 text-gray-800 hover:bg-blue-500 hover:text-white'
                    : darkMode
                    ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
                    : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                }`}
                disabled={!phase.present}
                whileHover={{ scale: phase.present ? 1.05 : 1 }}
                whileTap={{ scale: phase.present ? 0.95 : 1 }}
              >
                {phase.name}
              </motion.button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const Modal = ({ isOpen, onClose, children }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className={`bg-${darkMode ? 'gray-800' : 'white'} p-6 rounded-lg w-11/12 max-w-3xl`}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className={`text-xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                  Time Series Graph for Asset: {data.assetDetail.bus_name}
                </h2>
                <button
                  onClick={onClose}
                  className={`p-1 rounded-full ${
                    darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
                  } transition-colors duration-200`}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className={`fixed right-0 top-20 z-[1000] w-96 h-[calc(100vh-12rem)] overflow-y-auto ${
            darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
          } rounded-l-lg shadow-lg`}
        >
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold">{title}</h2>
              <button
                onClick={onClose}
                className={`p-2 rounded-full ${
                  darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
                } transition-colors duration-200`}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            {renderAssetDetails()}
            {renderPhaseTable()}
            {(data.type === 'Node' || data.type === 'Load') && (
              <div className="mt-6">
                <motion.button
                  onClick={() => setIsModalOpen(true)}
                  className={`w-full px-4 py-2 rounded-lg ${
                    darkMode
                      ? 'bg-blue-600 hover:bg-blue-700 text-white'
                      : 'bg-blue-500 hover:bg-blue-600 text-white'
                  } transition-colors duration-200 flex items-center justify-center`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <ChartBarIcon className="h-5 w-5 mr-2" />
                  View Time Series Graph
                </motion.button>
              </div>
            )}
            <div className="space-y-4 mt-6">
              {Object.entries(data).map(([key, value]) => {
                if (!data.type && value !== null) {
                  return (
                    <motion.div
                      key={key}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`p-4 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-100'} shadow-md`}
                    >
                      <span className="font-semibold text-lg">{value === undefined ? 'No Asset Violations' : key}</span>
                      {renderValue(value)}
                    </motion.div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </motion.div>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {renderGraph()}
      </Modal>
    </AnimatePresence>
  );
};

export default RightPanel;