import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import { format, parseISO, differenceInMinutes, startOfWeek, endOfWeek} from 'date-fns';
import RangeSlider from "./util/RangeSlider/rangeSlider";
import RightPanel from "./util/RightPanel/rightPanel";
import KeyObservations from "./util/Observations/keyObservations";
import LoadingOverlay from "./util/LoadingOverlay/loadingOverlay";
import { ZoomIn, ZoomOut, RefreshCw, BarChart2, LineChart, ChevronDown, ChevronUp, FileText } from 'lucide-react';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, zoomPlugin);

// Cache object to store API responses with timestamps
const cache = {
    data: {},
    setItem: (key, value) => {
        const item = {
            value: value,
            timestamp: new Date().toISOString()
        };
        cache.data[key] = item;
        try {
            localStorage.setItem('dashboardCache', JSON.stringify(cache.data));
        } catch (error) {
            console.error('Error saving to localStorage:', error);
        }
    },
    getItem: (key) => {
        const item = cache.data[key];
        if (item) {
            return {
                value: item.value,
                timestamp: new Date(item.timestamp)
            };
        }
        return null;
    },
};

// Cache expiration time in minutes
const CACHE_EXPIRATION_TIME = 30;

// Load cache from localStorage on initial load
try {
    const storedCache = localStorage.getItem('dashboardCache');
    if (storedCache) {
        cache.data = JSON.parse(storedCache);
    }
} catch (error) {
    console.error('Error loading from localStorage:', error);
}


const Dashboard = ({ darkMode, selectedScenario, onScenarioChange }) => {
    const [selectedMetrics] = useState(['Over Voltage Violation', 'Under Voltage Violation', 'Line Violation']);
    const [timeRange, setTimeRange] = useState([parseISO('2023-01-01'), parseISO('2023-12-31T11:59:59')]);
    const [vTimeRange, setVTimeRange] = useState([parseISO('2023-01-01'), parseISO('2023-12-31T11:59:59')]);
    const [pTimeRange, setPTimeRange] = useState([parseISO('2023-01-01'), parseISO('2023-12-31T11:59:59')]);
    const [rTimeRange, setRTimeRange] = useState([parseISO('2023-01-01'), parseISO('2023-12-31T11:59:59')]);
    const [lTimeRange, setLTimeRange] = useState([parseISO('2023-01-01'), parseISO('2023-12-31T11:59:59')]);
    const [rightPanelOpen, setRightPanelOpen] = useState(false);
    const [rightPanelData, setRightPanelData] = useState(null);
    const [underVoltageViolations, setUnderVoltageViolations] = useState([]);
    const [overVoltageViolations, setOverVoltageViolations] = useState([]);
    const [lineViolations, setLineViolations] = useState([]);
    const [voltageData, setVoltageData] = useState([]);
    const [loadProfileData, setLoadProfileData] = useState([]);
    const [chartType, setChartType] = useState('bar'); // New state for chart type
    const [keyObservations, setKeyObservations] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [error, setError] = useState(null);
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [solutionGeneration,] = useState({
        0: 'A combination of non-wires solutions – demand flexibility from EV charging curtailment, battery storage, line switching – as well as wires solutions such as changing the wire gauge are analyzed and prioritized based on utility-defined criteria.'
    });
    const [timeScale, setTimeScale] = useState('');
    // New state for managing accordion sections
    const [openSections, setOpenSections] = useState({
        loadProfile: false,
        voltage: false,
        activePower: false,
        reactivePower: false,
        violations: false,
        keyObservations: false
    });
    const chartRef = useRef(null);
    const voltageChartRef = useRef(null);
    const powerChartRef = useRef(null);
    const reactivePowerChartRef = useRef(null);
    const loadChartRef = useRef(null);

    const fetchData = useCallback(async (forceRefresh = false) => {
        setLoading(true);
        setLoadingProgress(0);
        setError(null);

        const endpoints = [
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/underVoltage',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/overVoltage',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/pf-states',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/load-profile',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/lineviolations',
            'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/key-observations',
        ];

        try {
            for (let i = 0; i < endpoints.length; i++) {
                let endpoint = endpoints[i];
                if (selectedScenario === 'base') {
                    endpoint += '?scenario=base';
                  } else if (selectedScenario === 'er4109') {
                    endpoint += '?scenario=er300-4109';
                  } else if (selectedScenario === 'er4109_1') {
                    endpoint += '?scenario=er500-4109';
                  } else if (selectedScenario === 'er4139') {
                    endpoint += '?scenario=er300-4139';
                  } else if (selectedScenario === 'er7633') {
                    endpoint += '?scenario=er150-7633';
                  }

                const cacheKey = `${endpoint}_${selectedScenario}`;
                let data;
                let cachedItem = cache.getItem(cacheKey);

                if (!forceRefresh && cachedItem && differenceInMinutes(new Date(), cachedItem.timestamp) < CACHE_EXPIRATION_TIME) {
                    data = cachedItem.value;
                } else {
                    const response = await fetch(endpoint);
                    data = await response.json();
                    cache.setItem(cacheKey, data);
                }

                if (i === 0) {
                    if(data.message === 'No violations found.') {
                        setUnderVoltageViolations(data);
                    } else {
                    setUnderVoltageViolations(data.map(item => ({
                        x: parseISO(item.timestamp).toISOString(),
                        y: item.count
                    })));
                }
                } else if (i === 1) {
                    console.log(data);
                    if(data.message === 'No violations found.') {
                        setOverVoltageViolations(data);
                    } else {
                    setOverVoltageViolations(data.map(item => ({
                        x: parseISO(item.timestamp).toISOString(),
                        y: item.count
                    })));
                }
                } else if (i === 2) {
                    setVoltageData(data.map(item => ({
                        x: parseISO(item.timestamp).toISOString(),
                        mag_a: parseFloat(item.v_mag_a_pu),
                        mag_b: parseFloat(item.v_mag_b_pu),
                        mag_c: parseFloat(item.v_mag_c_pu),
                        ang_a: parseFloat(item.v_ang_a),
                        ang_b: parseFloat(item.v_ang_b),
                        ang_c: parseFloat(item.v_ang_c),
                        p_a_kw: parseFloat(item.p_a_kw),
                        p_b_kw: parseFloat(item.p_b_kw),
                        p_c_kw: parseFloat(item.p_c_kw),
                        q_a_kvar: parseFloat(item.q_a_kvar),
                        q_b_kvar: parseFloat(item.q_b_kvar),
                        q_c_kvar: parseFloat(item.q_c_kvar),
                    })));
                } else if (i === 3) {
                    setLoadProfileData(data.map(item => ({
                        x: parseISO(item.timestamp).toISOString(),
                        kw: parseFloat(item.kw),
                        kvar: parseFloat(item.kvar)
                    })));
                } else if (i === 4) {
                    if(data.message === 'No violations found.') {
                        setLineViolations(data);
                    } else {
                    setLineViolations(data.map(item => ({
                        x: parseISO(item.timestamp).toISOString(),
                        y: item.violations
                    })));
                }
                } else if (i === 5) {
                    setKeyObservations(data);
                }
                //calculate loading progress where its an int not a float
                const progress = ((i + 1) / endpoints.length) * 100;
                setLoadingProgress(progress.toFixed(0));
            }
            setLastUpdateTime(new Date());
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [selectedScenario]);

    useEffect(() => {
        fetchData();

        // Set up automatic refresh
        const refreshInterval = setInterval(() => {
            fetchData(true);  // Force refresh
        }, CACHE_EXPIRATION_TIME * 60 * 1000);  // Convert minutes to milliseconds

        return () => clearInterval(refreshInterval);  // Clean up on unmount
    }, [fetchData]);

    const data = useMemo(() => ({
        datasets: [
            {
                label: 'Over Voltage Violation',
                data: overVoltageViolations,
                borderColor: 'rgb(239, 68, 68)',
                backgroundColor: 'rgba(239, 68, 68, 0.5)',
            },
            {
                label: 'Under Voltage Violation',
                data: underVoltageViolations,
                borderColor: 'rgb(59, 130, 246)',
                backgroundColor: 'rgba(59, 130, 246, 0.5)',
            },
            {
                label: 'Line Violation',
                data: lineViolations,
                borderColor: 'rgb(41, 171, 135)',
                backgroundColor: 'rgba(41, 171, 135, 0.5)',
            }
        ]
    }), [underVoltageViolations, overVoltageViolations, lineViolations]);

    const voltageChartData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Magnitude',
                data: voltageData.map(item => ({ x: item.x, y: item.mag_a })),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y-magnitude',
            },
            {
                label: 'Phase B Magnitude',
                data: voltageData.map(item => ({ x: item.x, y: item.mag_b })),
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                yAxisID: 'y-magnitude',
            },
            {
                label: 'Phase C Magnitude',
                data: voltageData.map(item => ({ x: item.x, y: item.mag_c })),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                yAxisID: 'y-magnitude',
            },
        ]
    }), [voltageData]);

    const activePowerData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Active Power',
                data: voltageData.map(item => ({ x: item.x, y: item.p_a_kw })),
                borderColor: 'rgb(191, 189, 36)',
                backgroundColor: 'rgba(191, 189, 36, 0.5)',
                yAxisID: 'y-power',
            },
            {
                label: 'Phase B Active Power',
                data: voltageData.map(item => ({ x: item.x, y: item.p_b_kw })),
                borderColor: 'rgb(25, 53, 212)',
                backgroundColor: 'rgba(25, 53, 212, 0.5)',
                yAxisID: 'y-power',
            },
            {
                label: 'Phase C Active Power',
                data: voltageData.map(item => ({ x: item.x, y: item.p_c_kw })),
                borderColor: 'rgb(202, 235, 16)',
                backgroundColor: 'rgba(202, 235, 16, 0.5)',
                yAxisID: 'y-power',
            }
        ]
    }), [voltageData]);

    const reactivePowerData = useMemo(() => ({
        datasets: [
            {
                label: 'Phase A Reactive Power',
                data: voltageData.map(item => ({ x: item.x, y: item.q_a_kvar })),
                borderColor: 'rgb(255, 159, 64)',
                backgroundColor: 'rgba(255, 159, 64, 0.5)',
                yAxisID: 'y-rpower',
            },
            {
                label: 'Phase B Reactive Power',
                data: voltageData.map(item => ({ x: item.x, y: item.q_b_kvar })),
                borderColor: 'rgb(153, 102, 255)',
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
                yAxisID: 'y-rpower',
            },
            {
                label: 'Phase C Reactive Power',
                data: voltageData.map(item => ({ x: item.x, y: item.q_c_kvar })),
                borderColor: 'rgb(201, 203, 207)',
                backgroundColor: 'rgba(201, 203, 207, 0.5)',
                yAxisID: 'y-rpower',
            }
        ]
    }), [voltageData]);

    const loadData = useMemo(() => ({
        datasets: [
            {
                label: 'Active Power (p.u.)',
                data: loadProfileData.map(item => ({ x: item.x, y: item.kw })),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                yAxisID: 'y-kw',
            },
            {
                label: 'Reactive Power (p.u.)',
                data: loadProfileData.map(item => ({ x: item.x, y: item.kvar })),
                borderColor: 'rgb(191, 189, 36)',
                backgroundColor: 'rgba(191, 189, 36, 0.5)',
                yAxisID: 'y-kvar',
            }
        ]
    }), [loadProfileData]);

    const ChartComponent = chartType === 'line' ? Line : Bar;
    const VoltageChartComponent = Line;
    const PowerChartComponent = Line;
    const ReactivePowerChartComponent = Line;
    const LoadChartComponent = Line;

    const commonOptions = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                },
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM d',
                    }
                },
                title: {
                    display: true,
                    text: 'Time',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
    }), [darkMode]);

    const updateChartOptions = (options, scale) => {
        const newOptions = { ...options };
        if (scale === 'hourly') {
            newOptions.scales.x.time.unit = 'hour';
            newOptions.scales.x.time.displayFormats = { hour: 'HH:mm' };
        } else {
            newOptions.scales.x.time.unit = 'day';
            newOptions.scales.x.time.displayFormats = { day: 'MMM d' };
        }
        return newOptions;
    };

    const options = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Violations for Feeder: P1U',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            x: {
                ...commonOptions.scales.x,
                min: timeRange[0],
                max: timeRange[1],
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Violations',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
                stacked: chartType === 'bar',
            }
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = data.datasets[datasetIndex].data[index];
                // Format the timestamp for the API request
                const formattedTimestamp = parseISO(clickedData.x).toISOString();
                // Fetch detailed violations for the clicked timestamp
                let endpoint = 'https://stg.ext.thinklabs.ai/backend/api/v1/dashboard/graph/asset-details';
                if (selectedScenario === 'base') {
                    endpoint += '?scenario=base';
                  } else if (selectedScenario === 'er4109') {
                    endpoint += '?scenario=er300-4109';
                  } else if (selectedScenario === 'er4109_1') {
                    endpoint += '?scenario=er500-4109';
                  } else if (selectedScenario === 'er4139') {
                    endpoint += '?scenario=er300-4139';
                  } else if (selectedScenario === 'er7633') {
                    endpoint += '?scenario=er150-7633';
                  }
                if(data.datasets[datasetIndex].label !== 'Line Violation') {
                    fetch(`${endpoint}&timestamp=${formattedTimestamp}`)
                        .then(response => response.json())
                        .then(detailedData => {
                            setRightPanelData({
                                title: `${data.datasets[datasetIndex].label} Details`,
                                data: {
                                    "Violation Type": data.datasets[datasetIndex].label,
                                    "Number of Violations": clickedData.y,
                                    "Timestamp": format(clickedData.x, "yyyy-MM-dd HH:mm"),
                                    "Asset Violations": detailedData.violations?.map(violation => ({
                                        "Asset ID": violation.asset_id,
                                        "Phase": violation.phase,
                                        "Voltage": violation.voltage
                                    }))
                                }
                            });
                            setRightPanelOpen(true);
                        })
                        .catch(error => console.error('Error fetching violation details:', error));
                } else {
                    setRightPanelData({
                        title: `${data.datasets[datasetIndex].label} Details`,
                        data: {
                            "Violation Type": data.datasets[datasetIndex].label,
                            "Number of Violations": clickedData.y,
                            "Timestamp": format(clickedData.x, "yyyy-MM-dd HH:mm"),
                        }
                    });
                    setRightPanelOpen(true);
                }
            }
        }
    }, timeScale), [commonOptions, darkMode, chartType, data.datasets, selectedScenario, timeRange, timeScale]);

    const voltageOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Voltage Magnitude Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: vTimeRange[0],
                max: vTimeRange[1],
            },
            'y-magnitude': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Voltage Magnitude (p.u.)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = voltageChartData.datasets[datasetIndex].data[index];
                const title = `Voltage Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Magnitude": clickedData.y,
                            "Angle": voltageData[index].ang_a,
                        },
                        "Phase B": {
                            "Magnitude": voltageData[index].mag_b,
                            "Angle": voltageData[index].ang_b,
                        },
                        "Phase C": {
                            "Magnitude": voltageData[index].mag_c,
                            "Angle": voltageData[index].ang_c,
                        }

                    }
                });
                setRightPanelOpen(true);
            }
        }
    }, timeScale), [commonOptions, darkMode, voltageChartData.datasets, voltageData, vTimeRange, timeScale]);

    const pOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Active Power Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: pTimeRange[0],
                max: pTimeRange[1],
            },
            'y-power': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Active Power (kW)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = activePowerData.datasets[datasetIndex].data[index];
                const title = `Active Power Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Active Power": clickedData.y,
                            "Reactive Power": voltageData[index].q_a_kvar
                        },
                        "Phase B": {
                            "Active Power": voltageData[index].p_b_kw,
                            "Reactive Power": voltageData[index].q_b_kvar
                        },
                        "Phase C": {
                            "Active Power": voltageData[index].p_c_kw,
                            "Reactive Power": voltageData[index].q_c_kvar
                        }
                    }
                });
                setRightPanelOpen(true);
            }
        }
    }, timeScale), [commonOptions, darkMode, activePowerData.datasets, voltageData, pTimeRange, timeScale]);

    const rOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Reactive Power Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: rTimeRange[0],
                max: rTimeRange[1],
            },
            'y-rpower': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Reactive Power (kVAR)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = reactivePowerData.datasets[datasetIndex].data[index];
                const title = `Reactive Power Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Phase A": {
                            "Reactive Power": clickedData.y,
                            "Active Power": voltageData[index].p_a_kw
                        },
                        "Phase B": {
                            "Reactive Power": voltageData[index].q_b_kvar,
                            "Active Power": voltageData[index].p_b_kw
                        },
                        "Phase C": {
                            "Reactive Power": voltageData[index].q_c_kvar,
                            "Active Power": voltageData[index].p_c_kw
                        }
                    }
                });
                setRightPanelOpen(true);
            }
        }
    }, timeScale), [commonOptions, darkMode, reactivePowerData.datasets, voltageData, rTimeRange, timeScale]);

    const lOptions = useMemo(() => updateChartOptions({
        ...commonOptions,
        plugins: {
            ...commonOptions.plugins,
            title: {
                display: true,
                text: 'Load Profile Against Time for Representative Node',
                color: darkMode ? '#E5E7EB' : '#1F2937',
            },
        },
        scales: {
            ...commonOptions.scales,
            x: {
                ...commonOptions.scales.x,
                min: lTimeRange[0],
                max: lTimeRange[1],
            },
            'y-kw': {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Active Power (p.u.)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
            'y-kvar': {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: 'Reactive Power (p.u.)',
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                ticks: {
                    color: darkMode ? '#E5E7EB' : '#1F2937',
                },
                grid: {
                    color: darkMode ? 'rgba(229, 231, 235, 0.1)' : 'rgba(31, 41, 55, 0.1)',
                },
            },
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const datasetIndex = elements[0].datasetIndex;
                const index = elements[0].index;
                const clickedData = loadData.datasets[datasetIndex].data[index];
                const title = `Load Profile Details for ${format(clickedData.x, "MMM d, yyyy HH:mm")}`;
                setRightPanelData({
                    title: title,
                    data: {
                        "Time": format(clickedData.x, "MMM d, yyyy HH:mm:ss"),
                        "Active Power": clickedData.y,
                        "Reactive Power": loadProfileData[index].kvar
                    }
                });
                setRightPanelOpen(true);
            }
        }
    }, timeScale), [commonOptions, darkMode, loadData.datasets, loadProfileData, lTimeRange, timeScale]);

    const ScaleSelector = ({ value, onChange, darkMode }) => (
        <select
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={`p-2 rounded ${darkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700'}`}
        >
            <option value="" disabled>Choose a Scale</option>
            <option value="weekly">Weekly</option>
            <option value="hourly">Hourly</option>
        </select>
    );

    const handleScaleChange = (newScale) => {
        setTimeScale(newScale);
        // Adjust the time ranges based on the new scale
        const adjustTimeRange = (currentRange) => {
            if (newScale === 'hourly') {
                // Set to the first day of the current range
                return [startOfWeek(currentRange[0]), endOfWeek(currentRange[0])];
            } else {
                // Expand to a full week
                return [startOfWeek(currentRange[0]), endOfWeek(currentRange[1])];
            }
        };
        setTimeRange(adjustTimeRange(timeRange));
        setVTimeRange(adjustTimeRange(vTimeRange));
        setPTimeRange(adjustTimeRange(pTimeRange));
        setRTimeRange(adjustTimeRange(rTimeRange));
        setLTimeRange(adjustTimeRange(lTimeRange));
    };

    const ChartTypeSelector = ({ value, onChange, darkMode }) => (
        <div className="flex items-center space-x-2">
            <span className={`text-sm ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Chart Type:</span>
            <button
                onClick={() => onChange('line')}
                className={`p-2 rounded ${value === 'line' 
                    ? (darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white') 
                    : (darkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700')}`}
            >
                <LineChart size={20} />
            </button>
            <button
                onClick={() => onChange('bar')}
                className={`p-2 rounded ${value === 'bar' 
                    ? (darkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white') 
                    : (darkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700')}`}
            >
                <BarChart2 size={20} />
            </button>
        </div>
    );

    const handleCloseRightPanel = () => {
        setRightPanelOpen(false);
    };

    const filteredData = useMemo(() => ({
        ...data,
        datasets: data.datasets.filter(ds => selectedMetrics.includes(ds.label))
    }), [data, selectedMetrics]);

    const handleRangeChange = useCallback((newRange) => {
        setTimeRange(newRange);
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    }, []);

    const handleVoltageRangeChange = useCallback((newRange) => {
        setVTimeRange(newRange);
        if (voltageChartRef.current) {
            voltageChartRef.current.resetZoom();
        }
    }, []);

    const handlePRangeChange = useCallback((newRange) => {
        setPTimeRange(newRange);
        if (powerChartRef.current) {
            powerChartRef.current.resetZoom();
        }
    }, []);

    const handleRRangeChange = useCallback((newRange) => {
        setRTimeRange(newRange);
        if (reactivePowerChartRef.current) {
            reactivePowerChartRef.current.resetZoom();
        }
    }, []);

    const handleLRangeChange = useCallback((newRange) => {
        setLTimeRange(newRange);
        if (loadChartRef.current) {
            loadChartRef.current.resetZoom();
        }
    }, []);

    const handleZoomIn = () => {
        if (chartRef.current) {
            chartRef.current.zoom(1.5);
        }
    };

    const handleVoltageZoomIn = () => {
        if (voltageChartRef.current) {
            voltageChartRef.current.zoom(1.5);
        }
    };

    const handlePZoomIn = () => {
        if (powerChartRef.current) {
            powerChartRef.current.zoom(1.5);
        }
    };

    const handleRZoomIn = () => {
        if (reactivePowerChartRef.current) {
            reactivePowerChartRef.current.zoom(1.5);
        }
    };

    const handleLZoomIn = () => {
        if (loadChartRef.current) {
            loadChartRef.current.zoom(1.5);
        }
    };

    const handleZoomOut = () => {
        if (chartRef.current) {
            chartRef.current.zoom(0.9);
        }
    };

    const handleVoltageZoomOut = () => {
        if (voltageChartRef.current) {
            voltageChartRef.current.zoom(0.9);
        }
    };

    const handlePZoomOut = () => {
        if (powerChartRef.current) {
            powerChartRef.current.zoom(0.9);
        }
    };

    const handleRZoomOut = () => {
        if (reactivePowerChartRef.current) {
            reactivePowerChartRef.current.zoom(0.9);
        }
    };

    const handleLZoomOut = () => {
        if (loadChartRef.current) {
            loadChartRef.current.zoom(0.9);
        }
    };

    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    const handleVoltageResetZoom = () => {
        if (voltageChartRef.current) {
            voltageChartRef.current.resetZoom();
        }
    };

    const handlePResetZoom = () => {
        if (powerChartRef.current) {
            powerChartRef.current.resetZoom();
        }
    };

    const handleRResetZoom = () => {
        if (reactivePowerChartRef.current) {
            reactivePowerChartRef.current.resetZoom();
        }
    };

    const handleLResetZoom = () => {
        if (loadChartRef.current) {
            loadChartRef.current.resetZoom();
        }
    };

    const toggleSection = (section) => {
        setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
    };

    if (loading || error) {
        return (
            <LoadingOverlay
                darkMode={darkMode}
                progress={loadingProgress}
                error={error}
                onRetry={() => fetchData(true)}
            />
        );
    }

    return (
        <div className={`dashboard ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
            <div className="p-4">
                <div className="p-4 flex justify-between items-center">
                    <h2 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Key Observations</h2>
                    <div className="flex items-center space-x-4">
                        <button className={`flex items-center justify-center p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <FileText size={20} />
                            <span className="ml-2">Generate Report</span>
                        </button>
                    </div>
                </div>
                
                {/* Key Observations */}
                <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <KeyObservations observations={keyObservations} darkMode={darkMode} />
                </div>

                <div className="p-4 flex justify-between items-center">
                    <h2 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Time Series Plots</h2>
                    <div className="flex items-center space-x-4">
                        <div className="flex items-center space-x-2">
                            <span className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>Feeder: P1U</span>
                        </div>
                        {/* <ScaleSelector value={timeScale} onChange={handleScaleChange} darkMode={darkMode} /> */}
                    </div>
                </div>

                {/* Load Profile Chart */}
                <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('loadProfile')} >
                        <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Load Profile</h3>
                        {openSections.loadProfile ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </div>
                    {openSections.loadProfile && 
                    <div className="mt-4">
                        <div className="h-[50vh]">
                        <LoadChartComponent ref={loadChartRef} options={lOptions} data={loadData} />
                    </div>
                    <div className="p-4">
                        <RangeSlider
                            min={parseISO('2023-01-01')}
                            max={parseISO('2023-12-31T11:59:59')}
                            value={lTimeRange}
                            onChange={handleLRangeChange}
                            darkMode={darkMode}
                        />
                    </div>
                    <div className="flex justify-center p-4 space-x-2">
                        <button onClick={handleLZoomIn} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomIn size={20} />
                        </button>
                        <button onClick={handleLZoomOut} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomOut size={20} />
                        </button>
                        <button onClick={handleLResetZoom} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <RefreshCw size={20} />
                        </button>
                    </div>
                    </div>}
                </div>

                {/* Voltage Chart */}
                <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('voltage')} >
                        <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Voltage</h3>
                        {openSections.voltage ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </div>
                    {openSections.voltage && 
                    <div className="mt-4">
                        <div className="h-[50vh]">
                        <VoltageChartComponent ref={voltageChartRef} options={voltageOptions} data={voltageChartData} />
                    </div>
                    <div className="p-4">
                        <RangeSlider
                            min={parseISO('2023-01-01')}
                            max={parseISO('2023-12-31T11:59:59')}
                            value={vTimeRange}
                            onChange={handleVoltageRangeChange}
                            darkMode={darkMode}
                        />
                    </div>
                    <div className="flex justify-center p-4 space-x-2">
                        <button onClick={handleVoltageZoomIn} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomIn size={20} />
                        </button>
                        <button onClick={handleVoltageZoomOut} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomOut size={20} />
                        </button>
                        <button onClick={handleVoltageResetZoom} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <RefreshCw size={20} />
                        </button>
                    </div>
                    </div>}
                </div>

                {/* Active Power Chart */}
                <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('activePower')} >
                        <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Active Power</h3>
                        {openSections.activePower ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </div>
                    {openSections.activePower && 
                    <div className="mt-4">
                        <div className="h-[50vh]">
                        <PowerChartComponent ref={powerChartRef} options={pOptions} data={activePowerData} />
                    </div>
                    <div className="p-4">
                        <RangeSlider
                            min={parseISO('2023-01-01')}
                            max={parseISO('2023-12-31T11:59:59')}
                            value={pTimeRange}
                            onChange={handlePRangeChange}
                            darkMode={darkMode}
                        />
                    </div>
                    <div className="flex justify-center p-4 space-x-2">
                        <button onClick={handlePZoomIn} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomIn size={20} />
                        </button>
                        <button onClick={handlePZoomOut} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomOut size={20} />
                        </button>
                        <button onClick={handlePResetZoom} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <RefreshCw size={20} />
                        </button>
                    </div>
                    </div>}
                </div>

                {/* Reactive Power Chart */}
                <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('reactivePower')} >
                        <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Reactive Power</h3>
                        {openSections.reactivePower ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </div>
                    {openSections.reactivePower && 
                    <div className="mt-4">
                        <div className="h-[50vh]">
                        <ReactivePowerChartComponent ref={reactivePowerChartRef} options={rOptions} data={reactivePowerData} />
                    </div>
                    <div className="p-4">
                        <RangeSlider
                            min={parseISO('2023-01-01')}
                            max={parseISO('2023-12-31T11:59:59')}
                            value={rTimeRange}
                            onChange={handleRRangeChange}
                            darkMode={darkMode}
                        />
                    </div>
                    <div className="flex justify-center p-4 space-x-2">
                        <button onClick={handleRZoomIn} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomIn size={20} />
                        </button>
                        <button onClick={handleRZoomOut} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <ZoomOut size={20} />
                        </button>
                        <button onClick={handleRResetZoom} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                            <RefreshCw size={20} />
                        </button>
                    </div>
                    </div>}
                </div>
                {/* Violations Chart */}
                {(overVoltageViolations.message === 'No violations found.' && underVoltageViolations.message === 'No violations found.') ? (
                    <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('violations')} >
                        <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Violations</h3>
                        {openSections.violations ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </div>
                    {openSections.violations && 
                    <div className="mt-4">
                        <div className="mt-8 p-6 rounded-lg shadow-lg text-center">
                            <h2 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>No Voltage Violations for {selectedScenario} scenario</h2>
                        </div>
                    </div>}
                </div>
                ) : (
                    <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleSection('violations')} >
                        <h3 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Violations</h3>
                        {openSections.violations ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                    </div>
                    {openSections.violations && 
                    <div className="mt-4">
                        <div className="p-4 flex justify-between items-center mb-4">
                            <ChartTypeSelector className="pr-3" value={chartType} onChange={setChartType} darkMode={darkMode} />
                        </div>
                        <div className="h-[50vh]">
                            <ChartComponent ref={chartRef} options={options} data={filteredData} />
                        </div>
                        <div className="p-4">
                            <RangeSlider
                                min={parseISO('2023-01-01')}
                                max={parseISO('2023-12-31T11:59:59')}
                                value={timeRange}
                                onChange={handleRangeChange}
                                darkMode={darkMode} />
                        </div>
                        <div className="flex justify-center p-4 space-x-2">
                            <button onClick={handleZoomIn} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                                <ZoomIn size={20} />
                            </button>
                            <button onClick={handleZoomOut} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                                <ZoomOut size={20} />
                            </button>
                            <button onClick={handleResetZoom} className={`p-2 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'}`}>
                                <RefreshCw size={20} />
                            </button>
                        </div>
                    </div>}
                </div>
                )}
                <div className="p-4 flex justify-between items-center">
                    <h2 className={`text-xl font-semibold ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>Solution Generation</h2>
                </div>
                {/* Solution Generation */}
                <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <KeyObservations observations={solutionGeneration} darkMode={darkMode} />
                </div>
            </div>
            <RightPanel
                isOpen={rightPanelOpen}
                onClose={handleCloseRightPanel}
                panelData={rightPanelData}
                darkMode={darkMode}
            />
            <div className={`mt-4 text-right ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                Last updated: {lastUpdateTime ? format(lastUpdateTime, 'yyyy-MM-dd HH:mm:ss') : 'N/A'}
            </div>
        </div>
    );
};

export default Dashboard;