import React from 'react';

const KeyObservations = ({ observations, darkMode }) => {
  return (
    <div className={`p-4 rounded-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
      {/* <h3 className={`text-lg font-semibold mb-2 ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>Key Observations</h3> */}
      <ul className="list-disc pl-5 space-y-1">
        {Object.values(observations).map((observation, index) => {
          if (!observation) return null;
          return (
            <li key={index} className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>{observation}</li>
          );
        })}
      </ul>
    </div>
  );
};

export default KeyObservations;