import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Eye, EyeOff, Check, X } from 'lucide-react';
import { confirmSignIn } from 'aws-amplify/auth';

const ChangePassword = ({ darkMode, email, onPasswordChange }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  // Password validation states
  const [validations, setValidations] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    passwordsMatch: false
  });

  const validatePassword = (password) => {
    const validationState = {
      minLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };

    setValidations(prev => ({
      ...validationState,
      passwordsMatch: confirmPassword === password
    }));

    return Object.values(validationState).every(Boolean);
  };

  const updatePasswords = (newPass, confirmPass) => {
    const validationState = {
      minLength: newPass.length >= 8,
      hasUpperCase: /[A-Z]/.test(newPass),
      hasLowerCase: /[a-z]/.test(newPass),
      hasNumber: /\d/.test(newPass),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPass),
      passwordsMatch: newPass === confirmPass
    };

    setValidations(validationState);
  };

  const handleNewPasswordChange = (e) => {
    const newPass = e.target.value;
    setNewPassword(newPass);
    updatePasswords(newPass, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPass = e.target.value;
    setConfirmPassword(confirmPass);
    updatePasswords(newPassword, confirmPass);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    if (!email) {
      setError('Email is required. Please sign in again.');
      setLoading(false);
      return;
    }
  
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }
  
    // Validate password strength
    if (!validatePassword(newPassword)) {
      setError('Please meet all password requirements');
      setLoading(false);
      return;
    }
  
    try {
        const storedSignInResult = sessionStorage.getItem('signInResult');
        if (!storedSignInResult) {
        throw new Error('No sign-in information found. Please sign in again.');
        }

        await confirmSignIn({
        challengeResponse: newPassword
        });
        // Clear any stored sign-in result
        sessionStorage.removeItem('signInResult');
  
        onPasswordChange();
        navigate('/geojson');
    } catch (error) {
        if (error.message.includes('already authenticated')) {
            // If user is already authenticated, just proceed
            sessionStorage.removeItem('signInResult');
            onPasswordChange();
            navigate('/geojson');
        } else {
            setError(error.message || 'Failed to change password. Please try again.');
        }
    } finally {
      setLoading(false);
    }
  };

  const ValidationItem = ({ satisfied, text }) => (
    <div className="flex items-center space-x-2">
      {satisfied ? (
        <Check className="h-4 w-4 text-green-500" />
      ) : (
        <X className="h-4 w-4 text-red-500" />
      )}
      <span className={satisfied ? 'text-green-500' : 'text-red-500'}>
        {text}
      </span>
    </div>
  );

  return (
    <div className={`flex items-center justify-center min-h-screen bg-gradient-to-br ${
      darkMode ? 'from-gray-900 to-gray-700' : 'from-blue-400 to-purple-500'
    }`}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md px-4"
      >
        <div className={`px-8 py-6 text-left ${
          darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
        } shadow-2xl rounded-xl`}>
          <div className="flex justify-center mb-6">
            <img
              src={darkMode ? 'https://www.thinklabs.ai/images/logo.png' : '/icons/logo-light.jpg'}
              alt="Thinklabs Logo"
              className="h-12 w-auto"
            />
          </div>

          <h2 className="text-2xl font-bold text-center mb-8">Set New Password</h2>
          
          {error && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded"
            >
              {error}
            </motion.div>
          )}

          <div className="mb-6 text-center">
            <span className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Changing password for: {email}
            </span>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className={`block text-sm font-medium ${
                darkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                New Password
              </label>
              <div className="relative mt-1">
                <input
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  className={`block w-full px-3 py-2 ${
                    darkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300 text-gray-900'
                  } border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  className={`absolute inset-y-0 right-0 px-3 flex items-center ${
                    darkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-600 hover:text-gray-800'
                  }`}
                >
                  {showNewPassword ? (
                    <EyeOff className="h-4 w-4" aria-hidden="true" />
                  ) : (
                    <Eye className="h-4 w-4" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>

            <div>
              <label className={`block text-sm font-medium ${
                darkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Confirm New Password
              </label>
              <div className="relative mt-1">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className={`block w-full px-3 py-2 ${
                    darkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300 text-gray-900'
                  } border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className={`absolute inset-y-0 right-0 px-3 flex items-center ${
                    darkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-600 hover:text-gray-800'
                  }`}
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-4 w-4" aria-hidden="true" />
                  ) : (
                    <Eye className="h-4 w-4" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>

            <div className={`text-sm space-y-2 p-4 rounded-lg ${
              darkMode ? 'bg-gray-700' : 'bg-gray-50'
            }`}>
              <h3 className="font-medium mb-2">Password Requirements:</h3>
              <ValidationItem 
                satisfied={validations.minLength} 
                text="At least 8 characters" 
              />
              <ValidationItem 
                satisfied={validations.hasUpperCase} 
                text="At least one uppercase letter" 
              />
              <ValidationItem 
                satisfied={validations.hasLowerCase} 
                text="At least one lowercase letter" 
              />
              <ValidationItem 
                satisfied={validations.hasNumber} 
                text="At least one number" 
              />
              <ValidationItem 
                satisfied={validations.hasSpecialChar} 
                text="At least one special character" 
              />
              <ValidationItem 
                satisfied={validations.passwordsMatch} 
                text="Passwords match" 
              />
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={loading || !Object.values(validations).every(Boolean)}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading || !Object.values(validations).every(Boolean)
                  ? 'bg-indigo-400 cursor-not-allowed' 
                  : 'bg-indigo-600 hover:bg-indigo-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              type="submit"
            >
              {loading ? (
                <div className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Changing Password...
                </div>
              ) : (
                'Change Password'
              )}
            </motion.button>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default ChangePassword;