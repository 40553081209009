import { useState, useEffect, useCallback } from 'react';

export const useNotifications = (userEmail) => {
  // Use user-specific storage key
  const storageKey = userEmail ? `notifications_${userEmail}` : null;

  // Initialize notifications from localStorage with user-specific key
  const [notifications, setNotifications] = useState(() => {
    try {
      if (!storageKey) return [];
      const saved = localStorage.getItem(storageKey);
      return saved ? JSON.parse(saved) : [];
    } catch (error) {
      console.error('Error loading notifications:', error);
      return [];
    }
  });

  const [unreadCount, setUnreadCount] = useState(() => {
    try {
      return notifications.filter(n => !n.read).length;
    } catch (error) {
      console.error('Error calculating unread count:', error);
      return 0;
    }
  });

  // Update localStorage whenever notifications or user changes
  useEffect(() => {
    try {
      if (storageKey) {
        localStorage.setItem(storageKey, JSON.stringify(notifications));
      }
      setUnreadCount(notifications.filter(n => !n.read).length);
    } catch (error) {
      console.error('Error saving notifications:', error);
    }
  }, [notifications, storageKey]);

  // Clear notifications when user changes
  useEffect(() => {
    if (!storageKey) {
      setNotifications([]);
      setUnreadCount(0);
    }
  }, [storageKey]);

  const addNotification = useCallback((message) => {
    if (!storageKey) return;
    
    const newNotification = {
      id: Date.now(),
      message: typeof message === 'string' ? message : message.message,
      timestamp: new Date().toLocaleString(),
      read: false
    };

    setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
  }, [storageKey]);

  const markAllAsRead = useCallback(() => {
    if (!storageKey) return;
    
    setNotifications(prevNotifications =>
      prevNotifications.map(notification => ({
        ...notification,
        read: true
      }))
    );
  }, [storageKey]);

  const clearNotifications = useCallback(() => {
    if (!storageKey) return;
    setNotifications([]);
  }, [storageKey]);

  return {
    notifications,
    unreadCount,
    addNotification,
    clearNotifications,
    markAllAsRead
  };
};