import React from 'react';

const ViolationSummary = ({ darkMode }) => {
  return (
    <div className={`flex flex-col min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} py-8 sm:py-12`}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Violations</h2>
            <p className={`mt-2 text-3xl font-bold tracking-tight ${darkMode ? 'text-gray-100' : 'text-gray-900'} sm:text-4xl`}>
              Data Summary and Insights
            </p>
          </div>
          <div className="mx-auto mt-8 max-w-2xl sm:mt-12 lg:mt-16 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {[
                {
                  title: "Consistent Voltage Levels",
                  description: "The voltage readings for each measurement point remain constant throughout the entire period, showing no fluctuations.",
                  icon: (
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  )
                },
                {
                  title: "Slight Voltage Differences",
                  description: "Although the voltage levels are constant, there are small differences between the four measurement points, with the P1ULV7633 points consistently showing higher readings.",
                  icon: (
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 13.125C3 12.504 3.504 12 4.125 12h15.75c.621 0 1.125.504 1.125 1.125v6.75C21 20.496 20.496 21 19.875 21H4.125A1.125 1.125 0 013 19.875v-6.75z"
                      />
                    </svg>
                  )
                },
                {
                  title: "Stable Electrical System",
                  description: "The dataset suggests a very stable electrical system with minimal voltage fluctuations, though the consistent differences between measurement points may be worth investigating further.",
                  icon: (
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  )
                },
                {
                  title: "Complete and Consistent Data",
                  description: "The dataset is complete with no gaps in the readings, and the measurements are taken at consistent 15-minute intervals throughout the operating hours for both days.",
                  icon: (
                    <svg
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5h-1.5c-.621 0-1.125.504-1.125 1.125m1.125-1.125v1.5c0 .621-.504 1.125-1.125 1.125M3.375 8.25c0 .621.504 1.125 1.125 1.125h1.5v-1.5A1.125 1.125 0 005.625 6.75m0 8.25h1.5a1.125 1.125 0 001.125 1.125 1.125 1.125 0 001.125-1.125v-1.5c0-.621.504-1.125 1.125-1.125h1.5c.621 0 1.125.504 1.125 1.125v1.5a1.125 1.125 0 001.125 1.125 1.125 1.125 0 001.125-1.125v-1.5c0-.621.504-1.125 1.125-1.125h1.5c.621 0 1.125.504 1.125 1.125v1.5a1.125 1.125 0 001.125 1.125 1.125 1.125 0 001.125-1.125v-1.5c0-.621.504-1.125 1.125-1.125h1.5c.621 0 1.125.504 1.125 1.125v1.5"
                      />
                    </svg>
                  )
                }
              ].map((item, index) => (
                <div key={index} className="relative pl-16">
                  <dt className={`text-base font-semibold leading-7 ${darkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      {item.icon}
                    </div>
                    {item.title}
                  </dt>
                  <dd className={`mt-2 text-base leading-7 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViolationSummary;